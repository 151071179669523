import React, { useEffect, useState } from "react";
import Layout from '../../components/layout/Layout'
import moment from "moment";
import { getAllNews, getLastNews, saveNews } from "../../services/news";
import NewsDisplay from "../../components/news/NewsDisplay";
import toast from "react-hot-toast";
import { useIsMobile } from "../../hooks/useIsMobile";
import { navigate } from "gatsby";
// Importing styles
import "react-quill/dist/quill.snow.css";


// Importing core components
// const QuillEditor = dynamic(() => import('react-quill'), { ssr: false });

const QuillEditor = typeof window === 'object' ? require('react-quill') : () => false;




const isBrowser = typeof window !== "undefined"

const AddNewsPage = () => {
  moment.locale('fr');

  const {isMobile } = useIsMobile()

  const handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  const [fontSize, setFontSize] = useState(12)
  const [message, setMessage] = useState("")
  const [title, setTitle] = useState("")

  const [allNews, setAllNews] = useState([])

  // Needed because the auth plugin add a grey overlay to the whole page, and don't delete it after auth :o
  const fixAuthOverlay = () => {
    const overlay = document.querySelector('div[style*="height:100vh"][style*="background:#424242"]');
    if (overlay) {
      overlay.style.height = "auto";  // Supprime la hauteur forcée
      overlay.style.background = "transparent"; // Supprime le fond gris
      overlay.style.display = "block"; // Assure qu'il reste visible normalement
    }
  };

  useEffect(() => {
    setTimeout(fixAuthOverlay, 300); // Attendre un peu avant de supprimer l'overlay
  }, []);

  useEffect(() => {
    handleGetAllNews()
  }, [])

  const handleGetAllNews = async () => {
    const response = await getAllNews();
    setAllNews(response)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const news = {
      fontSize: parseInt(fontSize, 10),
      message: message,
      title : title
    };

    try {
      const response = await saveNews(news)
      if(response.creationDateObj) {
        alert("News créé !");
        if(isBrowser) {
          window.location.reload();
        }
      } else {
        toast.error(`Erreur lors de la création de la news.`);
      }
    } catch (error) {
      console.error("Error posting news:", error);
      alert("Erreur lors de la création de la news : ", error);
    }
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "clean",
  ];

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline','strike'],
      ['link'],
      ['clean']
    ],
  }

  return (
    <Layout>
      <section className="section" style={{backgroundColor : "#ffffff", paddingTop : "0rem"}}>
        <div className="container">
          <div className="content">
            <div className="column is-12" row>
              <div className={"calendrier-header"} style={{marginBottom : 0}}>
                <div className="side-button-absolute">
                  <button className="button has-text-terracota">
                    <a href={"/calendrier"} className={"has-text-terracota"}>
                      Calendrier
                    </a>
                  </button>
                </div>
                <h2 style={{justifyContent : isMobile ? "start" : "center", width : "100%", display : "flex"}}>Nouvelle news :</h2>
              </div>
              <div className="columns is-mobile has-text-justified">
                <div className="column is-12">
                  <div className="news-form">
                    <form
                      name="news"
                      method="post"
                      action="/"
                      onSubmit={handleSubmit}
                    >
                      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                      <input type="hidden" name="form-name" value="contact" />
                      <div hidden>
                        <label>
                          Don’t fill this out:{' '}
                          <input name="bot-field" onChange={handleChange} />
                        </label>
                      </div>
                      {/*<div className="field">*/}
                      {/*  <label className="label" htmlFor={'name'}>*/}
                      {/*    FontSize*/}
                      {/*  </label>*/}
                      {/*  <div className="control">*/}
                      {/*    <input*/}
                      {/*      className="input"*/}
                      {/*      type={'number'}*/}
                      {/*      onChange={(e) => setFontSize(e.target.value)}*/}
                      {/*      value={fontSize}*/}
                      {/*      // required={true}*/}
                      {/*    />*/}
                      {/*  </div>*/}
                      {/*</div>*/}

                      <div className="field">
                        <div className="control">
                          {isBrowser &&
                          <QuillEditor
                            // className={styles.editor}
                            theme="snow"
                            value={message}
                            formats={formats}
                            modules={modules}
                            onChange={(value) => setMessage(value)}
                          />
                          }
                        </div>
                      </div>
                      <p style={{fontStyle : "italic"}}>Pour ne rien afficher, envoyez une news vide !!</p>

                      <div className="field">
                        <button className="button has-text-terracota is-link" style={{ backgroundColor: 'black'}} type="submit">
                          Envoyer
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className={"calendrier-header"} style={{marginBottom : 0}}>
                <h2 style={{justifyContent : isMobile ? "start" : "center", width : "100%", display : "flex"}}>Ancienne news :</h2>
              </div>
              <div className={"news-list"}>
                {allNews.map((news) => {
                  return (
                    <NewsDisplay news={news}/>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout >
  )}


export default AddNewsPage
